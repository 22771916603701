import React from "react"
import styled from "styled-components"

import Image from "./image"
import SocialLinks from "./social-links"
import { FlexContainerHorizontal, TwoColumnsGrid } from "./containers"
import { profilePictureURLFromEmail } from "../helpers/functions"

const GridCell = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfilePicture = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 1px solid lightgray;
`

const AuthorName = styled.h3`
  margin-bottom: 0;
`

const AuthorBio = styled.p`
  font-size: 18px;
  color: #505050;
`

export const AuthorCell = ({ author }) => (
  <GridCell>
    <FlexContainerHorizontal style={{ marginBottom: "16px" }}>
      <ProfilePicture
        source={profilePictureURLFromEmail(author.email)}
        alt={`${author.name}`}
      />
      <div style={{ marginLeft: "24px" }}>
        <AuthorName>{author.name}</AuthorName>
        <SocialLinks links={author.socialLinks} alignment="left" />
      </div>
    </FlexContainerHorizontal>
    {author.bio && <AuthorBio>{author.bio}</AuthorBio>}
  </GridCell>
)

const AuthorsGrid = ({ authors, style }) => (
  <TwoColumnsGrid style={style}>
    {authors.map(author => (
      <AuthorCell key={author.name} author={author} />
    ))}
  </TwoColumnsGrid>
)

export default AuthorsGrid
