import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Authors from "../components/authors"
import Page from "../components/page"
import PageHero from "../components/page-hero"
import {
  ArticlePublishedDate,
  ArticleTitleLarge,
} from "../components/blog-elements"
import { SectionLarge } from "../components/elements"

import PodcastLinks from "../components/podcast-links"
import Comments from "../components/comments"
import NewsletterForm from "../components/newsletter-form"

import "../components/podcast.css"

const PodcastPageHeader = ({ title, date }) => (
  <PageHero title={title}>
    <div style={{ width: "100%", maxWidth: "1160px" }}>
      <section>
        <ArticleTitleLarge className="podcast__title heading">
          {title}
        </ArticleTitleLarge>
        <ArticlePublishedDate className="podcast__date" date={date} />
        <PodcastLinks layout="left" />
      </section>
    </div>
  </PageHero>
)

const PodcastPagePresenters = styled.div`
  background-color: #fafafa;
  padding: 100px 64px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 32px 24px;
  }
`

export default function PodcastPageTemplate({ data }) {
  const { episode, coverData, marketingImageData } = data
  const { fields, metadata, html, excerpt } = episode
  const { title } = metadata
  const { date, slug } = fields
  const coverImageContainer = coverData.edges[0] || {
    node: { childImageSharp: { fluid: {} } },
  }
  const coverImageURL = coverImageContainer.node.childImageSharp.fluid.src
  let marketingImageContainer = null
  let marketingImageURL = null
  if (marketingImageData) {
    marketingImageContainer = marketingImageData.edges[0] || {
      node: { childImageSharp: { fluid: {} } },
    }
    marketingImageURL = marketingImageContainer.node.childImageSharp.fluid.src
  }
  return (
    <Page
      title={title}
      description={excerpt}
      imageURL={coverImageURL}
      marketingImageURL={marketingImageURL}
      meta={[
        {
          name: `date`,
          content: date,
        },
      ]}
      slug={slug}
      heroContent={<PodcastPageHeader {...metadata} {...fields} />}
    >
      <section className="podcast__container">
        <article
          className="podcast"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
      <SectionLarge>
        <NewsletterForm />
      </SectionLarge>
      <SectionLarge>
        <PodcastPagePresenters>
          <h2 style={{ margin: "0 0 64px 0" }}>Presentato da...</h2>
          <Authors style={{ gridGap: "128px" }} />
        </PodcastPagePresenters>
      </SectionLarge>
      <section>
        <Comments {...metadata} {...fields} />
      </section>
    </Page>
  )
}

export const pageQuery = graphql`
  query PodcastByPath(
    $path: String!
    $cover: String!
    $marketingImage: String
    $tags: [String]!
  ) {
    coverData: allFile(filter: { relativePath: { eq: $cover } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    marketingImageData: allFile(
      filter: { relativePath: { eq: $marketingImage } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    episode: markdownRemark(fields: { slug: { eq: $path } }) {
      html
      ...Podcast
    }
    relatedEpisodesData: allMarkdownRemark(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fields: { slug: { ne: $path } }
        frontmatter: { tags: { in: $tags } }
      }
    ) {
      relatedEpisodes: nodes {
        ...BlogPost
      }
    }
  }
`
